import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { getTimeToDisplayString } from '../commons/dateAndTimeHelpers'
import { determineEventColor } from '../commons/eventColors'

export const CalendarMonthDay = ({ day }) => {
  const history = useHistory()
  const { url } = useRouteMatch()

  const isToday = () => {
    const today = new Date(new Date()).toISOString().split('T')[0]
    return today === day.date
  }

  const thisOrOtherMonthStyle = isToday() ? '' : day.inactive ? 'bg-gray-300 text-white' : 'bg-gray-700 text-white'
  const eventColorCodingStyle = (event) => `border-l-4 border-${determineEventColor(event.organizer.displayName)}-600`

  const isInPastStyle = day.inactive ? 'opacity-60' : ''

  const dateText = isToday() ? 'heute' : new Date(day.date).getUTCDate()

  const openEvent = (id) => {
    history.push({ pathname: `${url}/event/${id}` })
  }

  return (
    <div className="bg-white">
      <div className={`leading-5 border-b border-black text-lg ${thisOrOtherMonthStyle}`}>{dateText}</div>
      <div className="pt-0.5">
        {day?.events?.length > 0 &&
          day.events
            .sort((a, b) => {
              if (!a.start.dateTime) return -1
              if (!b.start.dateTime) return 1
              return a.start.dateTime > b.start.dateTime ? 1 : -1
            })
            .map((event) => (
              <div
                className={`
                text-left pl-0.5 font-secondary pt-0.5 pb-0.5 mb-1 bg-gray-200 overflow-hidden cursor-pointer 
                leading-none text-tango-resp-sml tango-word-break
                md:leading-4 md:text-base
                max-h-6lines lg:max-h-3lines 
                ${isInPastStyle} ${eventColorCodingStyle(event)}`}
                onClick={() => openEvent(event.id)}
                key={event.id}
              >
                <b>{!!event.start.dateTime ? getTimeToDisplayString(event.start.dateTime) : ''}</b> {event.summary}
              </div>
            ))}
      </div>
    </div>
  )
}
