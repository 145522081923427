import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { displayMonth } from './calendarDisplayFunctions'

export function CalendarNavigationButtons({ ...props }) {
  const { year, month } = useParams()
  const history = useHistory()
  const [currentYear, setCurrentYear] = React.useState(year ? parseInt(year) : new Date().getFullYear())
  const [currentMonth, setCurrentMonth] = React.useState(month ? parseInt(month) - 1 : new Date().getMonth())

  const navigatePrev = () => {
    const prevMonth = currentMonth > 0 ? currentMonth - 1 : 11
    setCurrentMonth(prevMonth)
    if (prevMonth === 11) {
      setCurrentYear(currentYear - 1)
      history.push(`/${currentYear - 1}/${displayMonth(prevMonth).calendarNum}`)
    } else {
      history.push(`/${currentYear}/${displayMonth(prevMonth).calendarNum}`)
    }
  }
  const navigateCurrent = () => {
    setCurrentMonth(new Date().getMonth())
    setCurrentYear(new Date().getFullYear())
    history.push(`/${new Date().getFullYear()}/${displayMonth(new Date().getMonth()).calendarNum}`)
  }
  const navigateNext = () => {
    const nextMonth = currentMonth < 11 ? currentMonth + 1 : 0
    setCurrentMonth(nextMonth)

    if (nextMonth === 0) {
      setCurrentYear(currentYear + 1)
      history.push(`/${currentYear + 1}/${displayMonth(nextMonth).calendarNum}`)
    } else {
      history.push(`/${currentYear}/${displayMonth(nextMonth).calendarNum}`)
    }
  }

  return (
    <div {...props}>
      <button
        className="border-t border-b border-l border-black border-solid hover:bg-gray-200 leading-10 px-3 focus:outline-none text-xl"
        onClick={navigatePrev}
      >
        &#9664;
      </button>
      <button
        className="border-t border-b border-r border-l border-black border-solid hover:bg-gray-200 leading-10 px-3 focus:outline-none text-xl"
        onClick={navigateCurrent}
      >
        heute
      </button>
      <button
        className="border-t border-b border-r border-black border-solid hover:bg-gray-200 leading-10 px-3 focus:outline-none text-xl"
        onClick={navigateNext}
      >
        &#9654;
      </button>
    </div>
  )
}
