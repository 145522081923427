import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Calendar } from './components/Calendar'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ErrorBoundary } from 'react-error-boundary'

const IFrameFallback = ({ error: { message, stack } }) => {
  return (
    <>
      <h1>oops something went wrong... displaying fallback:</h1>
      <iframe
        title="tango calendar"
        src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FVienna&amp;src=dGFuZ28uc2FsemJ1cmdAZ21haWwuY29t&amp;src=a3I0NTZjZ2Y3a2JmNXZqZTF0OHExMDV2YThAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=djllaWFhMmY1ZHVmaGE4ajg1MHBoNGEybDBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=MGR0bmcxMGt2bXVncmx0ZG44bzBmbmZydWdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=bHF2YnIxZGdqYmZnbnFob3IwZ21lYnB2dGdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=a2xqZjVmdmdkNDYxbW9rN3VuNHVjdXMxNGNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=dDI4dmsxYTBtbmFyaW9zZHRsMDNsMWZmMjBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=aGxnaXVkMGF1ZTB2MTlqdmFtaHA1b3V0MnNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=MDJlbGplMzV1cmtiaWlvZ3I1ZWdtNDZ2MHNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=Z211ZHFuaWxuMmQ5ZmI3ODBsZjVwNW1wcWdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=bW1wajM1Y2Nuc3Qzcmp1cmZxNWdudDdzaWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%2322AA99&amp;color=%2370237F&amp;color=%23AA5A00&amp;color=%23A87070&amp;color=%23743500&amp;color=%23B08B59&amp;color=%23227F63&amp;color=%23D6AE00&amp;color=%23871111&amp;color=%23743500&amp;color=%23B08B59&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=1"
        style={{ borderWidth: '0', width: '95vw', height: '90vh', minWidth: '500px', minHeight: '500px' }}
        frameBorder="0"
        scrolling="no"
      ></iframe>
      <input
        style={{ color: '#e85151', fontSize: '8px', fontFamily: 'monospace', width: '150px' }}
        type="text"
        value={`DEVELOPER INFO: ${message}: ${stack}`}
      />
    </>
  )
}

function App() {
  const queryClient = new QueryClient()
  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={IFrameFallback}>
        <div className="App md:p-2 max-w-7xl m-auto">
          <QueryClientProvider client={queryClient}>
            <Router basename={process.env.PUBLIC_URL}>
              <Switch>
                <Route path="/:year?/:month?/">
                  <Calendar />
                </Route>
              </Switch>
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </div>
      </ErrorBoundary>
    </div>
  )
}

export default App
