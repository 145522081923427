export const displayMonth = (monthNumber) => {
  switch (parseInt(monthNumber)) {
    case 0:
      return { name: 'Jänner', calendarNum: 1 }
    case 1:
      return { name: 'Februar', calendarNum: 2 }
    case 2:
      return { name: 'März', calendarNum: 3 }
    case 3:
      return { name: 'April', calendarNum: 4 }
    case 4:
      return { name: 'Mai', calendarNum: 5 }
    case 5:
      return { name: 'Juni', calendarNum: 6 }
    case 6:
      return { name: 'Juli', calendarNum: 7 }
    case 7:
      return { name: 'August', calendarNum: 8 }
    case 8:
      return { name: 'September', calendarNum: 9 }
    case 9:
      return { name: 'Oktober', calendarNum: 10 }
    case 10:
      return { name: 'November', calendarNum: 11 }
    case 11:
      return { name: 'Dezember', calendarNum: 12 }
    default:
      console.error(`unknown Month: ${parseInt(monthNumber)}`)
  }
}

export const displayWeekDay = (dayNumber) => {
  switch (dayNumber) {
    case 0:
      return { name: 'Sonntag', shortName: 'So', weekDayNum: 7 }
    case 1:
      return { name: 'Montag', shortName: 'Mo', weekDayNum: 1 }
    case 2:
      return { name: 'Dienstag', shortName: 'Di', weekDayNum: 2 }
    case 3:
      return { name: 'Mittwoch', shortName: 'Mi', weekDayNum: 3 }
    case 4:
      return { name: 'Donnerstag', shortName: 'Do', weekDayNum: 4 }
    case 5:
      return { name: 'Freitag', shortName: 'Fr', weekDayNum: 5 }
    case 6:
      return { name: 'Samstag', shortName: 'Sa', weekDayNum: 6 }
    default:
      console.error(`unknown Weekday: ${dayNumber}`)
  }
}
