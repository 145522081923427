import React from 'react'
import { Link, useParams } from 'react-router-dom'

export function EventPopUpHeaderClose({ ...props }) {
  const { year, month } = useParams()
  const closeEventUrl = `/${year}/${month}`

  return (
    <Link to={closeEventUrl} {...props}>
      &#x2715;
    </Link>
  )
}
