import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { displayMonth } from './calendarDisplayFunctions'
import { CalendarMonth } from './CalendarMonth'
import { CalendarNavigationButtons } from './CalendarNavigationButtons'

const headerStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 162px',
}
export function Calendar() {
  const { year, month } = useParams()
  const history = useHistory()

  const setToCurrentYearAndMonth = () => {
    history.push(`/${new Date().getFullYear()}/${displayMonth(new Date().getMonth()).calendarNum}`)
  }

  if (!year || !month) {
    setToCurrentYearAndMonth()
    return <></>
  }

  return (
    <>
      <div style={headerStyle} className="sticky z-10 inset-y-0 h-10 box-content pb-2 bg-white">
        <h1 className="text-tango-resp sm:text-4xl self-end inline-block">
          {displayMonth(month - 1).name} {year}
        </h1>
        <CalendarNavigationButtons className="absolute right-0" />
      </div>
      <CalendarMonth />
    </>
  )
}
