import React from 'react'
import { getTimeToDisplayString } from '../commons/dateAndTimeHelpers'
import { displayWeekDay } from './calendarDisplayFunctions'
import { determineEventColor } from '../commons/eventColors'
import { EventPopUpHeaderLocation } from './EventPopUpHeaderLocation'
import { EventPopUpHeaderClose } from './EventPopUpHeaderClose'
import { EventPopUpHeaderTimeAndDate } from './EventPopUpHeaderTimeAndDate'
import { EventPopUpHeaderTitle } from './EventPopUpHeaderTitle'
import { EventPopUpHeaderShare } from './EventPopUpHeaderShare'

const headerGridStyles = {
  gridTemplateColumns: '1fr 2.2rem',
}

export function EventPopUpHeader({ event }) {
  const isFullDayEvent = !event?.start.dateTime
  const eventStartDay = isFullDayEvent ? new Date(event?.start.date) : new Date(event?.start.dateTime)
  const startTimeString = isFullDayEvent ? null : getTimeToDisplayString(event?.start.dateTime)
  const endTimeString = isFullDayEvent ? null : getTimeToDisplayString(event?.end.dateTime)
  const timeString = isFullDayEvent ? null : `${startTimeString} - ${endTimeString}`

  const longDateDescription = () => {
    const dayString = displayWeekDay(eventStartDay.getDay()).name
    const startDateString = eventStartDay.toLocaleDateString('de')
    return `${dayString}, ${startDateString}`
  }

  const eventColor = determineEventColor(event?.organizer.displayName)

  const shareData = event
    ? {
        title: `${longDateDescription()} ${startTimeString} - ${endTimeString}`,
        text: event.summary,
        url: window.location.href,
      }
    : {}
  const deviceCanShare = window.navigator?.canShare && window.navigator?.canShare(shareData)

  return (
    <div style={headerGridStyles} className={`grid sticky bg-${eventColor}-200 border-l-4 border-${eventColor}-600`}>
      <div id="header-info" className="pl-1">
        <EventPopUpHeaderTimeAndDate time={timeString} date={longDateDescription()} className="pl-0.5 pt-1" />
        <EventPopUpHeaderTitle title={event.summary} className="text-2xl font-sans font-bold" />
        <EventPopUpHeaderLocation location={event.location} className="pl-0.5 pb-2 leading-4" />
      </div>
      <div id="header-actions">
        <EventPopUpHeaderClose className="block float-right text-center text-black bg-white text-2xl leading-9 w-9 h-9 md:leading-7 md:w-7 md:h-7" />
        <EventPopUpHeaderShare
          deviceCanShare={deviceCanShare}
          shareData={shareData}
          className="grid float-right bg-white text-2xl mt-1.5 w-9 h-9 md:mt-1 md:w-7 md:h-7"
        />
      </div>
    </div>
  )
}
