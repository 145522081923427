import React from 'react'

export function EventPopUpHeaderLocation({ location, ...props }) {
  const mapsLink = `https://maps.google.com/maps?hl=en&q=${encodeURIComponent(location)}`
  return (
    <div {...props}>
      {location} (
      <a href={mapsLink} target="_blank" rel="noopener noreferrer">
        Karte
      </a>
      )
    </div>
  )
}
