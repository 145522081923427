//colors have to be added to safelist in tailwind.config.js
export const determineEventColor = (organizerName) => {
  console.log('ON', organizerName)
  switch (organizerName) {
    case 'Angelika & Giorgos':
      return 'violet'
    case 'Eloy Prim':
      return 'orange'
    case 'Tango in Salzburg':
      return 'lime'
    case 'Tango Yellow':
      return 'yellow'
    case 'Tango Cyan':
      return 'cyan'
    case 'Tango Red':
      return 'red'
    case 'Tango Amphitheatrum':
      return 'blue'
    case 'Go-TanGo':
      return 'pink'
    default:
      return 'emerald'
  }
}
