import React from 'react'

export function EventPopUpHeaderTimeAndDate({ time, date, ...props }) {
  const isFullDayEvent = !!time
  return (
    <div {...props}>
      {isFullDayEvent && <span className="font-sans text-lg">{`${time}\u00A0\u00A0`}</span>}
      <span className="font-sans leading-7 ">{date}</span>
    </div>
  )
}
