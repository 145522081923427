import React from 'react'
import { useQuery } from 'react-query'
import { displayWeekDay } from './calendarDisplayFunctions'
import { loadCalendarEvents } from '../commons/calendarRequestsProvider'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { CalendarMonthDay } from './CalendarMonthDay'
import { EventPopUp } from './EventPopUp'

const loadingIndicator = {
  position: 'fixed',
  width: '100%',
  height: ' 100%',
  background: 'rgba(255,255,255,.8)',
  color: 'black',
  zIndex: '10',
}
const loadingTextStyle = {
  paddingBottom: '40vh',
}

const SUNDAY = 0

function adjustForMondayBeingFirstColumn(weekdayNumber) {
  if (weekdayNumber === SUNDAY) {
    return 6
  }
  return weekdayNumber - 1
}

export function indexOfFirstDayOfMonth(year, month) {
  return adjustForMondayBeingFirstColumn(new Date(Date.UTC(parseInt(year), parseInt(month))).getUTCDay())
}

export function calculateNextDay(year, month, idToFill) {
  const idx1St = indexOfFirstDayOfMonth(year, month)
  const offset = idToFill - idx1St + 1
  return new Date(Date.UTC(parseInt(year), parseInt(month), offset)).toISOString().split('T')[0]
}

function populateMonthViewWithLastDaysOfPreviousMonth(array, year, month) {
  for (let i = indexOfFirstDayOfMonth(year, month) - 1; i >= 0; i--) {
    const prevDay = calculateNextDay(year, month, i)
    array[i] = {
      date: prevDay,
      day: displayWeekDay(new Date(prevDay).getUTCDay()),
      inactive: true,
    }
  }
}

function populateMonthViewWithDaysOfCurrentAndNextMonth(array, year, month, numberOfDaysInMonthView) {
  const firstOfMonth = new Date(Date.UTC(parseInt(year), parseInt(month)))
  for (let i = indexOfFirstDayOfMonth(year, month); i < numberOfDaysInMonthView; i++) {
    let nextDay = calculateNextDay(year, month, i)
    const isNextMonth =
      new Date(nextDay).getUTCMonth() > firstOfMonth.getUTCMonth() || new Date(nextDay).getFullYear() > year
    array[i] = {
      date: nextDay,
      day: displayWeekDay(new Date(nextDay).getUTCDay()),
      inactive: isNextMonth,
    }
  }
}

export function determineMapKeyForDate(someDate) {
  const jsDate = new Date(someDate)
  return new Date(Date.UTC(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate())).toISOString().split('T')[0]
}

const createDaysMap = (daysArray) => {
  return daysArray.reduce((map, day) => {
    let key = day.date
    map[key] = day
    return map
  }, {})
}

const daysNeededForMonthView = (year, month) => {
  const firstOfMonth = new Date(year, month, 1)
  const lastOfMonth = new Date(year, month + 1, 0)
  const used = adjustForMondayBeingFirstColumn(firstOfMonth.getDay()) + lastOfMonth.getDate()
  return Math.ceil(used / 7) * 7
}

const createDaysOfMonthMap = (year, month, numberOfDaysInMonthView) => {
  let emptyMonth = [...Array(numberOfDaysInMonthView)]

  populateMonthViewWithLastDaysOfPreviousMonth(emptyMonth, year, month)
  populateMonthViewWithDaysOfCurrentAndNextMonth(emptyMonth, year, month, numberOfDaysInMonthView)
  return createDaysMap(emptyMonth)
}

function addEventsToDaysMap(daysMap, events) {
  events.forEach((e) => {
    let key = determineMapKeyForDate(!!e.start.dateTime ? e.start.dateTime : e.start.date)
    let entry = { ...daysMap[key] }
    entry.events = entry?.events ? [...entry.events, e] : [e]
    daysMap[key] = entry
  })
}

export function CalendarMonth() {
  const { path } = useRouteMatch()
  const { year: y, month: m } = useParams()
  const year = y ? parseInt(y) : new Date().getFullYear()
  const month = m ? parseInt(m) - 1 : new Date().getMonth()

  const numberOfDaysInMonthView = daysNeededForMonthView(year, month)

  const daysMap = createDaysOfMonthMap(year, month, numberOfDaysInMonthView)
  const daysMapKeys = Object.keys(daysMap)

  const lastDate = new Date(daysMapKeys[daysMapKeys.length - 1])
  lastDate.setDate(lastDate.getDate() + 1)

  const {
    isLoading,
    isError,
    data: events,
    error,
  } = useQuery(['events', year, month], () => loadCalendarEvents(daysMap[daysMapKeys[0]].date, lastDate))

  if (isError) {
    return <p className="text-red-600">{error}</p>
  }

  if (!isLoading && !isError) {
    addEventsToDaysMap(daysMap, events)
  }

  return (
    <div className="border-b md:border-r md:border-l border-black">
      {isLoading && (
        <div style={loadingIndicator} className="animate-pulse grid box-border inset-x-0">
          <span style={loadingTextStyle} className="text-4xl m-auto">
            loading...
          </span>
        </div>
      )}
      <div
        className={`grid gap-px tango-month-rows-${numberOfDaysInMonthView / 7} grid-cols-7 bg-black`}
        style={{ minHeight: '80vh' }}
      >
        <CalendarMonthWeekDays />
        {Object.keys(daysMap).map(
          (dateString) => daysMap[dateString]?.date && <CalendarMonthDay key={dateString} day={daysMap[dateString]} />
        )}
      </div>
      <Switch>
        <Route exact path={`${path}event/:id`}>
          <EventPopUp events={events} />
        </Route>
      </Switch>
    </div>
  )
}

const CalendarMonthWeekDays = () => {
  const height = 8
  return (
    <>
      {[1, 2, 3, 4, 5, 6, 0].map((weekdayNum) => (
        <div
          className={`sticky z-10 inset-y-12 h-${height} leading-${height} border-t border-b border-black bg-white text-lg`}
          key={weekdayNum}
        >
          {window.innerWidth > 700 ? displayWeekDay(weekdayNum).name : displayWeekDay(weekdayNum).shortName}
        </div>
      ))}
    </>
  )
}
