//example call: https://www.googleapis.com/calendar/v3/calendars/kr456cgf7kbf5vje1t8q105va8@group.calendar.google.com/events?calendarId=kr456cgf7kbf5vje1t8q105va8%40group.calendar.google.com&singleEvents=true&timeZone=Europe%2FVienna&maxAttendees=1&maxResults=250&sanitizeHtml=true&timeMin=2019-09-29T00%3A00%3A00%2B02%3A00&timeMax=2019-11-03T00%3A00%3A00%2B02%3A00&key=AIzaSyBNlYH01_9Hc5S1J9vuFmu2nUqBZJNAXxs
//              https://www.googleapis.com/calendar/v3/calendars/mmpj35ccnst3rjurfq5gnt7sik@group.calendar.google.com/events?calendarId=mmpj35ccnst3rjurfq5gnt7sik@group.calendar.google.com&singleEvents=true&timeZone=Europe%2FVienna&maxAttendees=1&maxResults=250&sanitizeHtml=true&timeMin=  2021-04-25T22:00:00.000ZT08%3A00%3A00%2B02%3A00&timeMax=2021-06-05T22:00:00.000ZT23%3A59%3A00%2B02%3A00&key=AIzaSyBNlYH01_9Hc5S1J9vuFmu2nUqBZJNAXxs
import axios from 'axios'

const requestsFor = (minDate, maxDate) => {
  const calendarIds = {
    stefan: '02elje35urkbiiogr5egm46v0s@group.calendar.google.com',
    afterWorkMilonga: 't28vk1a0mnariosdtl03l1ff20@group.calendar.google.com',
    angelika: 'kr456cgf7kbf5vje1t8q105va8@group.calendar.google.com',
    amphitheatrum: 'gmudqniln2d9fb780lf5p5mpqg@group.calendar.google.com',
    eloy: 'v9eiaa2f5dufha8j850ph4a2l0@group.calendar.google.com',
    cyan: 'mmpj35ccnst3rjurfq5gnt7sik@group.calendar.google.com',
    gotango: '0dtng10kvmugrltdn8o0fnfrug@group.calendar.google.com',
    jhonny: 'lqvbr1dgjbfgnqhor0gmebpvtg@group.calendar.google.com',
    wolfgang: 'kljf5fvgd461mok7un4ucus14c@group.calendar.google.com',
    red: 'hlgiud0aue0v19jvamhp5out2s@group.calendar.google.com',
    yellow: 'kva8cr7h3anff9aohut2mmgg58@group.calendar.google.com',
  }

  return Object.keys(calendarIds)
    .map((key) => calendarIds[key])
    .map(
      (calendarId) =>
        `https://www.googleapis.com/calendar/v3/calendars/${calendarId}` +
        `/events?calendarId=${calendarId}&singleEvents=true&maxAttendees=1&maxResults=250&sanitizeHtml=true&` +
        `timeMin=${minDate}&timeMax=${maxDate}&key=AIzaSyBNlYH01_9Hc5S1J9vuFmu2nUqBZJNAXxs`
    )
}

export const loadCalendarEvents = (minDate, maxDate) => {
  return axios
    .all(
      requestsFor(encodeURI(new Date(minDate).toISOString()), encodeURI(new Date(maxDate).toISOString())).map((url) =>
        axios.get(url)
      )
    )
    .then(
      axios.spread((...results) => {
        return [...results].map((result) => result.data.items).reduce((x, y) => x.concat(y), [])
      })
    )
}

//https://clients6.google.com/calendar/v3/calendars/0dtng10kvmugrltdn8o0fnfrug@group.calendar.google.com/events?calendarId=0dtng10kvmugrltdn8o0fnfrug@group.calendar.google.com&singleEvents=true&timeZone=Europe%2FVienna&maxAttendees=1&maxResults=250&sanitizeHtml=true&timeMin=2019-12-01T00%3A00%3A00%2B01%3A00&timeMax=2020-01-05T00%3A00%3A00%2B01%3A00&key=AIzaSyBNlYH01_9Hc5S1J9vuFmu2nUqBZJNAXxs
// https://www.googleapis.com/calendar/v3/calendars/hlgiud0aue0v19jvamhp5out2s@group.calendar.google.com/events?calendarId=hlgiud0aue0v19jvamhp5out2s@group.calendar.google.com&singleEvents=true&timeZone=Europe%2FVienna&maxAttendees=1&maxResults=250&sanitizeHtml=true&timeMin=2019-12-01T08%3A00%3A00%2B02%3A00&timeMax=2019-12-31T23%3A59%3A00%2B02%3A00&key=AIzaSyBNlYH01_9Hc5S1J9vuFmu2nUqBZJNAXxs
