import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { EventPopUpHeader } from './EventPopUpHeader'

const eventGridTemplateStyle = {
  position: 'sticky',
  margin: ' auto',
  minWidth: '20rem',
  maxHeight: ' 100vh',
  boxSizing: 'border-box',
  color: 'black',
  display: ' grid',
  gridTemplateRows: 'auto 1fr',
}

export function EventPopUp({ events }) {
  const { id, year, month } = useParams()
  const history = useHistory()
  const event = events?.find((e) => e.id === id)
  const closeEventUrl = `/${year}/${month}`

  return (
    <div
      id="screen-overlay"
      className="z-20 grid w-full h-full fixed inset-0 bg-black bg-opacity-50"
      onClick={() => history.push(closeEventUrl)}
    >
      {event && (
        <div
          id="popup-card"
          className={`relative m-auto max-w-prose max-h-full bg-white text-left font-secondary`}
          style={eventGridTemplateStyle}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <EventPopUpHeader event={event} />
          <div
            className="whitespace-pre-wrap overflow-auto leading-5 pl-4 pt-4 pb-4"
            dangerouslySetInnerHTML={{ __html: event.description }}
          />
        </div>
      )}
    </div>
  )
}
